const footer = $(`<div class="footer-main">
  <div class="box">
    <p class="title">科技赋能 创新发展</p>
    <div class="footer-logo"></div>
    <p class="tips">天津越信融资担保有限责任公司</p>
    <div class="tel-wrap">
      <div class="tel-icon"></div>
      <p class="tel">400-665-2535（工作日：9:00-18:00）</p>
    </div>
    <div class="line"></div>
    <div class="tips-2"><i></i><p><a href="https://beian.miit.gov.cn" target="_blank">Copyright ©2022 津ICP备2022004987号</a></p></div>
  </div>
</div>`)
export default footer
